import React, { useState } from 'react';
import { Collapse } from 'antd';
// import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PlusOutlined from '../assets/svgs/plus-outlined.svg';
import MinusOutlined from '../assets/svgs/minus-outlined.svg';

import '../styles/HowItWorks/FaqClpsPanel.scss';
const { Panel } = Collapse;

const FaqClpsPanel = ({ data }) => {

    const data1 = [
        { header: "How soon can I have my CX team set up?", subtext: "This is details for Header 1" },
        { header: "How is quality ensured in my customer service agents?", subtext: "This is details for Header 2" },
        { header: "How will my team integrate with existing systems?", subtext: "This is details for Header 3" },
        { header: "How is AI in used in the customer service operations?", subtext: "This is details for Header 4" },
        { header: "What makes Inflexion Teams different from other BPOs?", subtext: "This is details for Header 5" },
        { header: "How will my team stay updated with the latest technologies?", subtext: "This is details for Header 6" }
    ];
    const data2 = [
        { header: "Why partner with Inflexion Teams for customer support?", subtext: "This is details for Header 1" },
        { header: "Is it possible to maintain my company's identity and service quality?", subtext: "This is details for Header 2" },
        { header: "What data security and privacy measures do you have in place?", subtext: "This is details for Header 3" },
        { header: "How does Inflexion Teams facilitate the shift from internal support to their managed services?", subtext: "This is details for Header 4" },
        { header: "How do you handle potential issues or apprehensions from our customers?", subtext: "This is details for Header 6" }
    ]

    const displayData = data == 'data1' ? data1 : data2
    const [activeKey, setActiveKey] = useState([]);

    const onPanelChange = key => {
        setActiveKey(key);
    };

    return (
        <Collapse className='faq-collapse-container' onChange={onPanelChange} activeKey={activeKey} accordion>
            {displayData.map((item, index) => (
                <Panel
                    className='faq-collapse-panel'
                    showArrow={false}
                    header={
                        <div className='faq-collapse-header' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1.05rem', alignItems: 'center' }}>
                            {item.header}
                            {activeKey.includes(index.toString()) ? <img src={MinusOutlined} /> : <img src={PlusOutlined} />}
                        </div>
                    }
                    key={index.toString()}
                >
                    {item.subtext}
                </Panel>
            ))}
        </Collapse>
    );
};

export default FaqClpsPanel